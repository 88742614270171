<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-12">
                <h2 class="fw-400 font-lg d-block"><b>Closed Questionnaire Submission List</b></h2>
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div v-if="!isLoad" class="col-12 py-3">
                        <div v-if="data.length" class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-hover table-bordered'" :columns="columns" :data="data" :page="page" :perPage="perPage">
                                <template name="default" slot-scope="{ row }">
                                    <tr v-if="row">
                                        <!-- <td>{{ index+1 }} | {{ page }}</td> -->
                                        <td class="cursor-pointer text-facebook" v-if="row.submission">
                                            <router-link :to="{name: 'ClosedQuestionnaireSubmissionDetail', params: {submissionId: row.submission.id, slug}}" target="_blank">{{ row.user.name }}</router-link>
                                        </td>
                                        <td v-else>
                                            {{ row.user.name }}
                                        </td>
                                        <td>{{ row.user.email }}</td>
                                        <td>
                                            <span v-if="row.submission == null" class="p-1 badge badge-warning text-white">Missing</span>
                                            <span v-else class="p-1 badge badge-success">Submitted</span>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="8">Nothing to see here</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>
                        <datatable-pager v-if="data.length" v-model="page" type="abbreviated"></datatable-pager>
                        <div v-if="isStillLoad" class="d-flex justify-content-end mt-2">
                            <p class="text-center">Overall data is still on loading</p>
                            <div class="spinner-grow text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div v-if="data.length == 0">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img :src="'/images/empty.png'" alt="" width="250">
                                        <h3 class="text-muted">Data is Empty!</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 text-center py-3">
                        <p>Loading data</p>
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    filters: {
        numfor: function(x) {
            return parseFloat(x).toFixed(2);
        }
    },
    data(){
        return {
            slug: this.$route.params.idCourse,
            id: this.$route.params.idQuestionnaire,
            isLoad: true,
            isStillLoad: true,
            columns: [
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Email', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Status', field: 'user.score', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},            
            ],
            data: [],
            page: 1,
            perPage: 10,
            tableFilter: ''
        }
    },
    created(){
        this.getSubmission()
    },
    methods: {
        formatUTCToLocal(datetime) {
            const date = new Date(datetime);

            // Pad single digit numbers with leading zero
            const pad = (num) => num.toString().padStart(2, '0');

            const day = pad(date.getDate());
            const month = pad(date.getMonth() + 1); // Months are zero-indexed
            const year = date.getFullYear();
            const hours = pad(date.getHours());
            const minutes = pad(date.getMinutes());

            return `${hours}:${minutes} | ${day}/${month}/${year}`;
        },
        async findStudent(){
            this.isStillLoad = true
            this.isLoad = true
            if(this.tableFilter == ''){
                this.getSubmissionList()
            }else{
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/search?slug=${this.slug}&id=${this.id}&user_email=${this.tableFilter}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.data = res.data.data
                }).catch(err => {
                    console.error(err)
                    this.data = []
                })
            }
            this.isLoad = false
            this.isStillLoad = false
        },
        compareDatesGreater(d1, d2 = null) {
            let date1 = moment(d1).format('YYYY-MM-DD H:m');
            let date2 = moment().format('YYYY-MM-DD H:m');
            if (d2) {
                date2 = new Date(d2).getTime();
            }
            if (date1 > date2) {
                return true;
            } else {
                return false;
            }
        },
        async getSubmission(){
            let queryLimit = 10;
            let queryOffset = 0;
            this.data = []
            while(this.isStillLoad){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/submission/summary?slug=${this.slug}&id=${this.id}&limit=${queryLimit}&offset=${queryOffset}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
                }).then(res => {
                    if (res.data.success){
                        console.log('DATA', res.data.data)
                        if (res.data.data.length == 0) {
                            this.isStillLoad = false
                            this.isLoad = false
                        } else {
                            this.data = [...this.data, ...res.data.data];
                            queryOffset = queryOffset + queryLimit
                            this.isLoad = false
                        }
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Submission !',
                            text: res.data.message,
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.isStillLoad = false
                    }
                    
                }).catch((err) => console.error(err.response))
            }
        },
        async getSubmissionList(){
            let queryLimit = 10;
            let queryOffset = 0;
            this.data = []

            while(this.isStillLoad){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/submission/summary?slug=${this.slug}&id=${this.id}&limit=${queryLimit}&offset=${queryOffset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if (res.data.success){
                        if(res.data.data.length == 0){
                            this.isStillLoad = false
                            this.isLoad = false
                        } else {
                            this.data = [...this.data, ...res.data.data];
                            queryOffset = queryOffset + queryLimit
                            this.isLoad = false
                            // console.log('CALLED', this.$refs.vueDatatable.processRows() ) 
                        }
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Submission !',
                            text: res.data.message,
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.isStillLoad = false
                    }
                    
                }).catch((err) => console.error(err.response))
            }
        },
    }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-content{
    max-height: 50vh;
    max-width: 100%;
    overflow: auto;
}

.table {
    min-width: 100wh;
}
::v-deep .ql-editor img {
    width: 100% !important;
}

.divider {
    min-width: 100%;
    height: 1px;
    background-color: black;
    opacity: 50%;
}

.card {
    min-width: 300px
}
</style>